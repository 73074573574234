<template>
  <div id="school">
    <div class="columns is-multiline">
      <div class="column is-12 has-text-centered">
        <figure class="image is-96x96 is-inline-block">
          <img :src="school.imageUrl" alt="avatar" v-if="school.imageUrl" />
          <img src="@/assets/images/default_avatar.png" alt="avatar" v-else />
        </figure>
      </div>
      <div class="column is-12">
        <p class="title has-text-centered">{{ school.name }}</p>
      </div>
      <div class="column is-12 right-align">
        <action-buttons
          :show-info="false"
          @initialize-update="initializeUpdate()"
          @initialize-remove="initializeRemove()"
          font-size="1.2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  data() {
    return {
      pageTitle: 'School',
      schoolId: null,
      school: {},
    }
  },
  apollo: {
    school: {
      query: gql`
        query SchoolQuery($id: ID!) {
          school(id: $id) {
            id
            imageUrl
            name
          }
        }
      `,
      variables() {
        return { id: this.schoolId }
      },
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setSubMenus', [
      // {
      //   name: "School Classes",
      //   route: `/school/${this.schoolId}/school_classes`
      // },
      // {
      //   name: "Promote",
      //   route: `/school/${this.schoolId}/school_class/${this.$route.params.id}/promote`
      // }
    ])
  },
  methods: {
    initializeUpdate() {
      this.$router.push(`/school/${this.schoolId}/edit`)
    },
    initializeRemove() {
      this.$buefy.notification.open({
        duration: 5000,
        message: 'Not Allowed.',
        position: 'is-top',
        type: 'is-danger',
        hasIcon: true,
      })
      return
    },
    remove() {},
  },
}
</script>
